<template>
  <b-row class="mt-2 mb-3">
    <b-col>
      <input class="form-control" type="date" v-model="form.start" />
    </b-col>
    <b-col>
      <input class="form-control" type="date" v-model="form.end" />
    </b-col>
    <b-col>
      <v-btn
  :loading="loading"
  class="font-weight-bolder bg-primary white--text font-size-sm rounded-lg"
  style="color: white;"
  @click="fetch"
>
  Lookup
</v-btn>

      <!-- <a class="btn btn-primary bg-primary font-weight-bolder mr-1 font-size-sm rounded-lg" @click="fetch">Lookup</a> -->
    </b-col>
  </b-row>
</template>

<script>
export default {
  name: "SearchWidget",
  data() {
    return {
      form: {
        start: new Date().toISOString().slice(0,10),
        end: new Date().toISOString().slice(0,10),
      },
      loading: false,

    };
  },
  methods: {
    async fetch(){
      this.loading = true;
      try {
        // Simulate a network request
        await new Promise(resolve => setTimeout(resolve, 2000));
        this.$emit('searchOrder', {
          "start": this.form.start,
          "end": this.form.end
        });
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>