<template>
  <div >
    <SearchWidget @searchOrder="getFetchOrders" />
    <b-row v-if="form.orders != ''">
      <b-col>
        <TotalOrdersWidget :orders="form.orders"></TotalOrdersWidget>
      </b-col>
      <b-col></b-col>
      <b-col></b-col>
    </b-row>
    <b-row v-if="form.orders != ''">
      <b-col>
        <OrdersTableWidget
          :orders="form.orders"
          :title="'Orders History'"
        ></OrdersTableWidget>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import TotalOrdersWidget from "@/view/pages/orders/components/TotalOrdersWidget.vue";
import SearchWidget from "@/view/pages/orders/components/SearchWidget.vue";
import OrdersTableWidget from "@/view/pages/orders/components/OrdersTableWidget.vue";
import { ORDERS } from "@/core/services/store/sales.module";
import { mapGetters } from "vuex";

export default {
  name: "TodayOrders",
  computed: {
    ...mapGetters(["currentUser"]),
  },
  components: {
    TotalOrdersWidget,
    SearchWidget,
    OrdersTableWidget,
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Orders" },
      { title: "Orders History" },
    ]);

    // look for current user
    if (this.currentUser.token !== "") {
      // call method to get orders
      this.fetchOrders({});
    }
  },

  data() {
    return {
      form: {
        orders: "",
      },
    };
  },
  methods: {
    getFetchOrders(obj){
      if  (obj.start && obj.end)
      {
        this.fetchOrders(obj)
      }
    },
    fetchOrders(obj) {
      this.$store
        .dispatch(ORDERS, obj)
        // go to which page after successfully login
        .then((resp) => {
          this.form.orders = resp.data;
          console.log(resp.data);
          // this.$router.push({ name: "dashboard" })
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>
